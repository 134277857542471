import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import dataV from "@jiaminghi/data-view";
import "amfe-flexible";
import { imgsPreloader } from "./imgPreloader";
import imgPreloaderList from "./imgPreLoaderList";
import i18n from "./locales/index";
import spacesRemove from "@/plugins/spacesRemove.js";
imgsPreloader(imgPreloaderList);

const app = createApp(App);
app.use(store);
app.use(dataV);
app.use(router);
app.use(spacesRemove);
app.use(ElementPlus);
app.use(i18n);
app.mount("#app");
