export default [
  require("./assets/首页/beijing1.png"),
  require("./assets/产品展示/beijing.png"),
  require("./assets/人力资源/beijing.png"),
  require("./assets/企业动态/beijing.png"),
  require("./assets/关于腾达/shoutu1.png"),
  require("./assets/营销网络/daohang.png"),
  require("./assets/联系我们/beijing.png"),
  require("./assets/investorrelationsbanner.png"),
];
