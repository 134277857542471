<template>
  <div style="cursor: pointer">
    <router-view />
  </div>
  <!-- 回到顶部 -->
  <el-backtop
    :bottom="50"
    :right="20"
    style="width: 40px; height: 40px; border-radius: 0"
  >
    <img
      style="width: 20px; height: 10px"
      src="./assets/首页/ding.png"
      alt=""
    />
  </el-backtop>
</template>
<script setup>
import Layout from "./views/Layout.vue";
import { onMounted } from "vue";
onMounted(() => {
  //new DevicePixelRatio().init();
  bodyScale();
  window.addEventListener("resize", bodyScale);
});
const bodyScale = () => {
  var devicewidth = document.documentElement.clientWidth;
  console.log("可是区域宽度" + devicewidth);
  var scale = devicewidth / 1920;
  document.body.style.zoom = scale;
};
const debounce = (fn, delay) => {
  let timer;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn();
    }, delay);
  };
};
</script>

<style lang="scss">
body {
  // overflow: visible !important;
  width: 1897px !important;
  padding: 0;
  margin: auto;
}

.global-v-html {
  white-space: pre-wrap;
  img {
    display: inline-block;
  }

  font-family: SourceHanSansSC;
  color: #000000;
  font-weight: 400px;
  font-size: 18px;
  font-style: normal;
  text-decoration: none;
  text-align: left;

  p {
    font-size: 18px !important;
  }

  span {
    font-size: 18px !important;
  }
}

p[data-f-id] {
  display: none;
}
.global_position {
  p:last-child[data-f-id] {
    display: none !important;
  }
}

.global_position p:last-child {
  display: none !important;
}
</style>
