<template>
  <!-- <navigationbar :flag="flag" /> -->
  <MyNavBar :flag="flag" v-if="store.state.isLogin" />
  <router-view></router-view>
  <pagefooter @contact="clicked" />
</template>
<script setup>
import MyNavBar from "../components/MyNavBar.vue";
import pagefooter from "../components/pagefooter.vue";
import { ref } from "vue";
import store from "@/store";
let flag = ref(false);
const clicked = (parms) => {
  flag.value = parms;
};
</script>
<style scoped lang="scss"></style>
