import axios from "axios";
const service = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? "/api" : "/",
  timeout: 100000,
});
// request interceptor
// service.interceptors.request.use(
//   (config) => {
//     // const token = localStorage.getItem("token")  || ''
//     // if(token) {

//     const token = localStorage.getItem("token");

//     token
//       ? (config.headers = {
//           "Content-Type": "application/json",
//           Authorization: token,
//         })
//       : (config.headers = {
//           "Content-Type": "application/x-www-form-urlencoded",
//         });

//     // }
//     return config;
//   },
//   (error) => {
//     // do something with request error
//     return Promise.reject(error);
//   }
// );
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.status !== 200 && res.Blob == "") {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (
        res.status === 50008 ||
        res.status === 50012 ||
        res.status === 50014
      ) {
        // to re-login
      }
      return Promise.reject(new Error(res.message || "Error"));
      // return res
    }
    if (res.status == 401) {
      
    }

    localStorage.setItem("token", "");
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default service;
