import request from "@/request/index";
//登录
export function login(data) {
  return request({
    url: "/login/checkout",
    method: "post",
    data,
  });
}

// 营销网络——国内国外
export function getList(data) {
  return request({
    url: "/fore/yxwl/getList.do",
    method: "post",
    data,
  });
}
// 图片集合接口
export function getimg(data) {
  return request({
    url: "/fore/img/getList.do",
    method: "post",
    data,
  });
}
// 人力资源-销售人员
export function recruit(data) {
  return request({
    url: "/fore/recruit/getList.do",
    method: "post",
    data,
  });
}
// 人力资源-销售人员-详情
export function getByid(data) {
  return request({
    url: "/fore/recruit/getByid.do",
    method: "post",
    data,
  });
}
// 在线留言
export function message(data) {
  return request({
    url: "/fore/message/save.do",
    method: "post",
    data,
  });
}
// 企业动态
export function news(data) {
  return request({
    url: "/fore/news/getList.do",
    method: "post",
    data,
  });
}
// 企业动态详情
export function news1(data) {
  return request({
    url: "/fore/news/getByid.do",
    method: "post",
    data,
  });
}
// 产品展示
export function getTabulation(data) {
  return request({
    url: "/fore/product/getList.do",
    method: "post",
    data,
  });
}
// 产品展示详情
export function getTabulation1(data) {
  return request({
    url: "/fore/product/getByid.do",
    method: "post",
    data,
  });
}

// 底部版权的接口
export function getCopyright(data) {
  return request({
    url: "/fore/home/getCopyright.do",
    method: "post",
    data,
  });
}

//导航栏产品展示
export function showProduction(data) {
  return request({
    url: "/fore/product/getProductShowList.do",
    method: "post",
    data,
  });
}

//新产品
export function newProduct(data) {
  return request({
    url: "/fore/product/getNewProductShowList.do",
    method: "post",
    data,
  });
}
//上传简历的文件
export function uploadFile(data) {
  console.log(data);
  return request({
    url: "/system/upload/uploadFile",
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data,
  });
}

//保存简历
export function saveCV(data) {
  return request({
    url: "/fore/recruit/save.do",
    method: "post",
    data,
  });
}
