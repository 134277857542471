export default (app) => {
  app.config.globalProperties.$spacesRemove = (str) => {
    if (typeof str == "string") {
      return str.replace(/\s+/g, "");
    } else {
      return str;
    }
  };

  return app;
};
