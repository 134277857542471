import { createStore } from "vuex";

export default createStore({
  getters: {},
  state: {
    isLogin: true,
  },
  mutations: {
    isLogin1(state, payload) {
      state.isLogin = payload;
    },
  },
  actions: {},
  modules: {},
});
