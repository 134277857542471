let index = 0;
const imgPreloader = (url) => {
  return new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = () => {
      index++;
      resolve();
    };
    image.onerror = () => {
      index++;
      reject();
    };
    image.src = url;
  });
};

let rid;
export const imgsPreloader = async (imgs) => {
  if (requestAnimationFrame) {
    if (imgs[index] !== undefined) {
      await imgPreloader(imgs[index]);
      requestAnimationFrame(() => {
        imgsPreloader(imgs);
      });

      if (index === imgs.length) {
        cancelAnimationFrame(rid);
      }
    }
  } else {
    if (imgs[index] !== undefined) {
      await imgPreloader(imgs[index]);
      imgsPreloader(imgs);
    }
  }
};
