<template>
  <div class="box">
    <div style="width: 1820px; margin: auto; position: relative;">
      <div class="title">
        <div style="margin: auto; display: flex">
          <p style="font-size: 36px; font-weight: 800; font-style: normal">
            TENGDA
          </p>
          <p style="font-size: 18px; margin-top: 12px; margin-left: 17px">
            同创伟业 共享腾达
          </p>
        </div>
      </div>
      <div class="content">
        <div class="content_1">
          <p style="opacity: 1; margin-top: 47px">联系我们</p>
          <p>地址：山东省枣庄市滕州市经济开发区鲁班大道北路1999号</p>
          <p>邮箱：north@tdfastener.com / marketing@tdfastener.com</p>
          <p>电话：0632-5677918 / 0632-5800288</p>
          <p>传真：0632-5677919 / 0632-5088827</p>
        </div>
        <div class="content_2">
          <div class="dimensional">
            <div style="width: 120px">
              <img src="../assets/首页/code1.png" alt="" />
              <p>电子样册</p>
            </div>
            <div style="width: 120px">
              <img src="../assets/首页/code2.png" alt="" />
              <p>腾达优购</p>
            </div>
          </div>
        </div>
        <div class="content_3">
          <div class="content_3_1">
            <p @click="router.push('/regard')">关于腾达</p>
            <p @click="router.push('/product')">产品展示</p>
            <p @click="router.push('/network')">销售网络</p>
            <p @click="router.push('/service')">技术服务</p>
            <p @click="add">在线留言</p>
            <a href="http://sale.tdfastener.com:18080/" target="_blank" style="text-decoration: none;">
              <p>腾达优购</p>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="lastLine">
        <p style="  opacity: 0.5;">@山东腾达紧固科技股份有限公司</p>
        <p @click="router.push('/login')" class="backM" style=" cursor: pointer; position: absolute; margin-left: 338px">
          后台管理</p>
        <p style="  opacity: 0.5;">
          Copyright &nbsp;&nbsp;&nbsp; 2020-2028 TENGDA
          &nbsp;&nbsp;&nbsp;技术支持&nbsp;&nbsp;&nbsp; 鲁ICP备17021460号-3
        </p>
      </div> -->
      <div class="lastLine" v-html="info">

      </div>
      <div @click="router.push('/login')" class="backEnd">
        后台管理
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRoute, useRouter } from "vue-router";
import { getCopyright } from '../api/index';
import { onMounted, ref } from "vue";
let ifshowd = ref(false);
let info = ref('');
const emit = defineEmits(["contact"]);
const add = () => {
  ifshowd.value = !ifshowd.value;
  emit('contact', ifshowd.value)
}


let copyright = async () => {
  const res = await getCopyright({ parms: JSON.stringify() });
  info.value = res.data.p_content;

}

copyright();
// onMounted(() => {

// })


const router = useRouter();
const route = useRoute();
</script>
<style scoped lang="scss">
.lastLine {
  margin-top: 37px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #fff;
  position: relative;
  font-weight: 400px;
  opacity: 0.5;

  // :deep(p) {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  // }
  :deep(p) {
    position: relative;
    bottom: 15px;
  }

  :deep(a) {
    color: #fff;
    text-decoration: none;
  }

  :deep(span:nth-child(1)) {

    margin-left: 886px;
  }

}

:deep(.lastLine .backM) {
  opacity: 1;
  color: white;

}

p {
  text-align: left;
  margin: 0;
  padding: 0;
}

.box {
  width: 100%;
  height: 572px;
  background-color: #0d0d0d;

  .title {
    height: 98px;
    display: flex;
    color: #fff;
    // background-color: aqua;
  }

  .content {
    width: 100%;
    height: 370px;
    display: flex;
    border-top: 1px solid rgb(56, 56, 56);
    border-bottom: 1px solid rgb(56, 56, 56);

    .content_1 {
      width: 616px;
      height: 100%;
      font-size: 22px;
      color: #fff;
      border-right: 1px solid rgb(56, 56, 56);

      p {
        opacity: 0.6000000238418579;
        margin-bottom: 30px;
      }
    }

    .content_2 {
      width: 586px;
      height: 100%;
      border-right: 1px solid rgb(56, 56, 56);

      .dimensional {
        width: 329px;
        height: 163px;
        margin: 125px auto;
        display: flex;
        justify-content: space-between;

        p {
          text-align: center;
          font-size: 16px;
          color: #fff;
          opacity: 0.6000000238418579;
          margin-top: 20px;
          font-weight: 600px;
        }
      }
    }

    .content_3 {
      width: 617px;
      height: 100%;

      .content_3_1 {
        margin-top: 143px;
        display: flex;
        flex-wrap: wrap;
        padding-left: 15px;

        p {
          margin-left: 26px;
          font-size: 22px;
          opacity: 0.6000000238418579;
          font-weight: 600px;
          height: 53px;
          color: #fff;
        }
      }
    }
  }

  .backEnd {
    cursor: pointer;
    position: absolute;
    width: 72px;
    height: 25px;

    bottom: 33.5px;
    left: 302px;
    color: #FFFFFF;
    font-weight: 400px;
    font-size: 18px;
    font-style: normal;
    text-decoration: none;
    text-align: left;
    /*后台管理*/
    font-family: PingFangSC;
    color: #FFFFFF;
    font-weight: 400px;
    font-size: 18px;
    font-style: normal;
    text-decoration: none;

  }
}
</style>
