<template>
  <div class="navcontainer">
    <!-- <div>{{ $t("message.hello") }}</div> -->
    <div class="pageoptions">
      <!-- <div class="threedots">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div> -->
      <div
        class="oneoption"
        @mouseenter="hoveron = item.name"
        @mouseleave="hoveron = ''"
        @click="goto(item.path, item.name)"
        v-for="(item, index) in navs"
        :index="index"
      >
        {{ item.name }}
        <div
          class=""
          v-if="hoveron === item.name || item.active.includes(route.path)"
          style="
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 25px;
            background-color: red;
          "
        ></div>
        <div
          class="dropdown_content"
          :class="item.path"
          v-if="item.dropdownC"
          @click.stop=""
        >
          <div
            class="dropitem"
            :style="{ height: it.h ? it.h + 'px' : '' }"
            v-for="it in item.dropdownC"
            @click.stop="
              hoveron === '产品展示'
                ? productdetail(it.sid)
                : hoveron === '人力资源' ||
                  hoveron === '资讯动态' ||
                  hoveron === '关于腾达'
                ? gotopage(it.path)
                : ''
            "
          >
            {{ it.p_name }}
          </div>
          <div
            :class="hoveron === '产品展示' ? 'tendasizeBig' : 'tendasizeSmall'"
          >
            TENGDA
          </div>
          <img
            v-if="hoveron === '资讯动态'"
            style="
              width: 130px;
              height: 110px;
              top: 18px;
              right: 40px;
              position: absolute;
              z-index: 1;
            "
            src="../assets/首页/sanjiao.png"
            alt=""
          />
          <img
            v-if="hoveron === '人力资源'"
            style="
              width: 130px;
              height: 130px;
              top: 18px;
              right: 40px;
              position: absolute;
              z-index: 1;
            "
            src="../assets/首页/manpowerBG.png"
            alt=""
          />
          <img
            v-if="hoveron === '关于腾达'"
            style="
              width: 130px;
              height: 140px;
              top: 18px;
              right: 40px;
              position: absolute;
              z-index: 1;
            "
            src="../assets/首页/mofang.png"
            alt=""
          />
        </div>
      </div>

      <!-- <div class="threedots dotsright">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div> -->
    </div>
    <!-- <div class="toggle" @click="switchLanguage"><span>中</span>/En</div> -->
    <div class="toggle"><span>中</span>/En</div>
    <div
      class="searchkeyword"
      :class="
        showkeywordsearch || keywordimportant ? 'searchkeyword_showbd' : ''
      "
      @mouseenter="showkeywordsearch = true"
      @mouseleave="showkeywordsearch = false"
      @keydown.enter="router.push('/series?keyword=' + inputVal)"
    >
      <el-icon color="white" :size="22">
        <Search />
      </el-icon>
      <!-- v-show="showkeywordsearch" -->
      <input
        @focus="keywordimportant = true"
        @blur="keywordimportant = false"
        v-model="inputVal"
        class="input"
        placeholder="请输入产品关键字"
        type="text"
        name=""
        id=""
      />
    </div>

    <div class="ispart">
      <div v-show="!showkeywordsearch && !keywordimportant" class="choice">
        标准查询
      </div>
      <img class="img" src="../assets/首页/chauxun.png" alt="" />
    </div>

    <div class="ispart" @click="centerDialogVisible = true">
      <div v-show="!showkeywordsearch && !keywordimportant" class="choice">
        在线留言
      </div>
      <img class="img" src="../assets/首页/liuyan.png" alt="" />
    </div>
    <a
      href="http://sale.tdfastener.com:18080/"
      target="_blank"
      style="text-decoration: none"
    >
      <div class="ispart">
        <div v-show="!showkeywordsearch && !keywordimportant" class="choice">
          腾达优购
        </div>

        <img class="img" src="../assets/首页/shangcheng.png" alt="" />
      </div>
    </a>
  </div>

  <el-dialog
    v-model="centerDialogVisible"
    width="1440px"
    :show-close="false"
    center
  >
    <div style="width: 100%; display: flex; justify-content: space-between">
      <div
        style="width: 410.5px; display: flex; justify-content: space-between"
      >
        <p class="dia_font">客户名称</p>
        <el-input v-model="input1" class="input1" placeholder="请输入" />
      </div>
      <div
        style="
          margin-left: 64.25px;
          width: 410.5px;
          display: flex;
          justify-content: space-between;
        "
      >
        <p class="dia_font">联系方式</p>
        <el-input v-model="input2" class="input1" placeholder="请输入" />
      </div>
      <div
        style="
          margin-left: 64.25px;
          width: 410.5px;
          display: flex;
          justify-content: space-between;
        "
      >
        <p class="dia_font">电子邮件</p>
        <el-input v-model="user_email" class="input1" placeholder="请输入" />
      </div>
    </div>
    <div style="display: flex; justify-content: space-between">
      <p class="dia_font" style="margin-right: 19.5px">留言内容</p>
      <el-input
        v-model="input4"
        style="margin-top: 20px; width: 1290px; height: 260px; font-size: 18px"
        :rows="10"
        type="textarea"
        resize="none"
        placeholder="请输入您的意见或建议"
      />
    </div>
    <template #footer>
      <span
        class="dialog-footer"
        style="
          width: 608px;
          margin: 50px auto;
          display: flex;
          justify-content: space-between;
        "
      >
        <el-button
          type="primary"
          style="
            width: 234px;
            height: 68px;
            font-size: 24px;
            background-color: #0033a1;
          "
          @click="submit()"
        >
          提交
        </el-button>
        <el-button
          style="width: 234px; height: 68px; font-size: 24px"
          @click="reset"
          >重置</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, computed, watch, defineProps } from "vue";
import SearchIpt from "./SearchIpt.vue";
import { Search } from "@element-plus/icons-vue";
import { useRoute, useRouter } from "vue-router";
import { message } from "../api/index";
import { ElMessage, inputEmits } from "element-plus";
import { showProduction } from "../api/index";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
const switchLanguage = () => {
  if (locale.value === "en") {
    locale.value = "zh";
  } else {
    locale.value = "en";
  }
};
const showkeywordsearch = ref(false);
const keywordimportant = ref(false);
const router = useRouter();
const route = useRoute();
const centerDialogVisible = ref(false);
const hoveron = ref("");
const inputVal = ref("");
const input1 = ref("");
const input2 = ref("");
const input4 = ref("");
const user_email = ref("");
const reset = () => {
  input1.value = "";
  input2.value = "";
  user_email.value = "";
  input4.value = "";
};
const gotopage = (path) => {
  router.push(path);
};
function submit() {
  var reg = /^([a-zA-Z\d][\w-]{2,})@(\w{2,})\.([a-z]{2,})(\.[a-z]{2,})?$/;

  var ret = reg.test(user_email.value);
  if (!input1.value) {
    ElMessage({
      message: "请输入客户名称",
      type: "error",
    });
    return;
  }
  const phoneReg =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

  if (!(input2.value && phoneReg.test(input2.value))) {
    ElMessage({
      message: "请输入正确的手机号",
      type: "error",
    });
    return;
  }
  if (ret) {
    message({
      parms: JSON.stringify({
        username: input1.value,
        telphone: input2.value,
        email: user_email.value,
        content: input4.value,
      }),
    }).then((res) => {});
    centerDialogVisible.value = false;
    ElMessage({
      message: "感谢您的留言！",
      type: "success",
    });
  } else {
    ElMessage({
      message: "邮箱格式错误",
      type: "error",
    });
  }
}
const props = defineProps({
  flag: Boolean,
});
watch(
  () => props.flag,
  (newv, oldv) => {
    centerDialogVisible.value = true;
  }
);
//产品展示
const productions = ref();
let getProduction = async () => {
  const res = await showProduction();
  navs.value[1].dropdownC = res.data;
  // console.log(navs.value[1].dropdownC);
};
getProduction();
const active = ref("");
const goto = (path, name) => {
  active.value = name;
  router.push("/" + path);
};
const productdetail = (Id) => {
  console.log(Id);
  router.push({ path: "/series?", query: { id: Id } });
};
const navs = ref([
  {
    name: "首页",
    path: "",
    active: ["/"],
  },
  {
    name: "产品展示",
    path: "product",
    active: ["/product", "/series"],
    dropdownC: "",
  },
  {
    name: "营销网络",
    path: "network",
    active: ["/network"],
  },
  {
    name: "技术服务",
    path: "service",
    active: ["/service"],
  },
  {
    name: "人力资源",
    path: "manpower",
    active: ["/manpower", "/campusrecruit", "/socialrecruit"],
    dropdownC: [
      {
        p_name: "人才理念",
        path: "/manpower",
      },

      {
        p_name: "校园招聘",
        path: "/campusrecruit",
      },

      {
        p_name: "社会招聘",
        path: "/socialrecruit",
      },
    ],
  },
  {
    name: "资讯动态",
    path: "dynamic",
    active: ["/dynamic", "/message"],
    dropdownC: [
      {
        p_name: "企业动态",
        path: "/dynamic",
      },
      {
        p_name: "信息公示",
        path: "/message",
      },
    ],
  },
  {
    name: "关于腾达",
    path: "regard",
    active: ["/regard", "/introductory", "/course", "/firm"],
    dropdownC: [
      {
        p_name: "公司简介",
        path: "/introductory",
      },
      {
        p_name: "发展历程",
        path: "/course",
      },
      {
        p_name: "企业文化",
        path: "/wonderfultengda",
      },
      // {
      //   p_name: "企业相关",
      //   path: "/firm",
      // },
    ],
  },
  {
    name: "投资者关系",
    path: "investorrelations",
    active: ["/investorrelations"],
  },
]);
</script>
<style lang="scss" scoped>
:deep(.el-input__icon) {
  font-size: 24px;
}

:deep(.el-input__wrapper) {
  background-color: #f1f1f1;
  // border: none;
  --el-input-border-color: none;
}
.el-input {
  --el-input-focus-border-color: none;
  --el-border-color-hover: none;
}
:deep(.el-textarea__inner) {
  background-color: #f1f1f1;
  font-size: 18px;
}

// 在线留言的输入框focus样式修改--留言内容
.el-textarea {
  --el-input-border-color: none;
  --el-input-focus-border-color: none;
  --el-border-color-hover: :none
}
.navcontainer {
  width: 1897px;
  height: 76px;
  //   background-color: brown;
  display: flex;
  align-items: center;
  background: transparent;
  position: absolute;
  top: 0px;
  font-size: 16px;
  border-bottom: 1px solid #f1f1f171;

  .threedots {
    width: 29px;
    height: 3px;
    display: flex;
    justify-content: space-between;
    .dot {
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: rgb(255, 255, 255);
    }

    .dot:nth-child(1) {
      background-color: rgba(255, 255, 255, 0.2);
    }
    .dot:nth-child(2) {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  .dotsright {
    .dot:nth-child(1) {
      background-color: rgba(255, 255, 255, 1);
    }
    .dot:nth-child(2) {
      background-color: rgba(255, 255, 255, 0.5);
    }
    .dot:nth-child(3) {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .pageoptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 920px;
    width: 760px;
    height: 76px;
    color: white;
    // margin-left: 500px;
    margin-left: 240px;

    .oneoption {
      position: relative;
      height: 76px;
      line-height: 76px;
      cursor: pointer;
      .dropdown_content {
        display: none;
        position: absolute;
        color: black;
        font-size: 18px;
        background-color: white;
        width: 320px;
        z-index: 90000000;
      }
    }
    .oneoption:hover {
      //   border-bottom: 1px solid red;
    }
    .oneoption:hover .dropdown_content {
      //   display: block;
      display: flex;
      border-radius: 5px;
    }

    .product {
      padding: 56px 20px;
      min-width: 520px;
      flex-wrap: wrap;
      min-height: 105px;
      padding: 20px 0;
      //   padding-bottom: 76px;
      //   justify-content: space-between;
      .dropitem {
        margin-left: 40px;
        position: relative;
        z-index: 2;
      }
    }

    .dropitem {
      height: 26px;
      line-height: 26px;
    }
    .manpower,
    .regard,
    .dynamic {
      min-width: 302px;
      min-height: 176px;
      flex-direction: column;
      flex-wrap: wrap;
      //padding: 18px 20px;
      padding-top: 18px;
      padding-left: 20px;
      background-color: white;
      //   padding-bottom: 40px;
      .dropitem {
        margin-bottom: 30px;
        width: fit-content;
        position: relative;
        z-index: 5;
      }
      .dropitem:last-child {
        margin-bottom: 0;
      }
    }
  }
      .toggle{
      color: #fff;
      font-size: 16px;
      margin-left: 207px;
      span{
        color: #FF0000;
      }
    }
  .searchkeyword {
    display: flex;
    align-items: center;
    height: 28px;
    max-width: 24px;
    overflow: hidden;
    border: white;
    margin-right: 30px;
    margin-left: 45px;
    padding-left: 5px;
    border-radius: 10px;
    input {
      background: transparent;
      border-color: transparent;
      color: white;
      margin-right: 5px;
      font-size: 12px;
    }
    input::placeholder {
      color: rgb(223, 223, 223);
    }
    .input:focus {
      border: 0;
      outline: none;
      box-shadow: none;
    }
  }
  .searchkeyword_showbd {
    border: 1px solid white;
    max-width: 200px;
    transition: all 1s;
  }

  .ispart {
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;
    margin-right: 30px;
    img {
      width: 22px;
      height: 22px;
    }
    .choice {
      margin-right: 5px;
    }
  }
}

.input1 {
  width: 310px;
  height: 66px;
  font-size: 18px;
  background-color: #f1f1f1 !important;
}

.dialog-footer button:first-child {
  margin-right: 10px;
}
.d1 {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.2000000029802322;
}

.d2 {
  width: 3px;
  height: 3px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.5;
}

.d3 {
  width: 3px;
  height: 3px;
  margin-left: 10px;
  border-radius: 50%;
  background-color: #fff;
}
.dia_font {
  font-family: SourceHanSansSC;
  color: #000000;
  font-weight: 400px;
  font-size: 20px;
  font-style: normal;
  text-decoration: none;
  text-align: left;
  width: 80px;
  height: 29px;
  margin-right: 20.5px;
}

.tendasizeBig {
  font-size: 90px;
  position: absolute;
  bottom: 10px;
  left: 63px;
  color: #f9f9f9;
  font-weight: 800;
}

.tendasizeSmall {
  font-size: 66px;
  position: absolute;
  bottom: 0px;
  left: 23px;

  color: #f9f9f9;
  font-weight: 800;
}
</style>
