import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Layout",
    component: () => import("../views/Layout.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/regard",
        name: "关于腾达",
        component: () => import("../views/list/regard.vue"),
      },
      {
        path: "/introductory",
        name: "关于腾达-公司简介",
        component: () => import("../views/list/introductory.vue"),
      },
      {
        path: "/course",
        name: "关于腾达-发展历程",
        component: () => import("../views/list/course.vue"),
      },
      {
        path: "/firm",
        name: "企业",
        component: () => import("../views/list/firm.vue"),
      },
      {
        path: "/manpower",
        name: "人力资源",
        component: () => import("../views/list/manpower.vue"),
      },
      {
        path: "/wonderfultengda",
        name: "精彩腾达",
        component: () => import("../views/list/wonderfultengda.vue"),
      },

      {
        path: "/campusrecruit",
        name: "校园招聘",
        component: () => import("../views/list/campusrecruit.vue"),
      },
      {
        path: "/socialrecruit",
        name: "社会招聘",
        component: () => import("../views/list/socialrecruit.vue"),
      },
      {
        path: "/position",
        name: "职位详情",
        component: () => import("../views/list/position.vue"),
      },
      {
        path: "/contactus",
        name: "联系我们",
        component: () => import("../views/list/contactus.vue"),
      },
      {
        path: "/investorrelations",
        name: "投资者关系",
        component: () => import("../views/list/investorrelations.vue"),
      },
      {
        path: "/network",
        name: "营销网络",
        component: () => import("../views/list/network.vue"),
      },
      {
        path: "/service",
        name: "技术服务",
        component: () => import("../views/list/service.vue"),
      },
      {
        path: "/product",
        name: "产品展示",
        component: () => import("../views/list/product.vue"),
      },
      {
        path: "/dynamic",
        name: "企业动态",
        component: () => import("../views/list/dynamic.vue"),
      },
      {
        path: "/dynamicdetails",
        name: "企业动态详情",
        component: () => import("../views/list/dynamicdetails.vue"),
      },
      {
        path: "/message",
        name: "信息公示",
        component: () => import("../views/list/message.vue"),
      },
      {
        path: "/messageDetails",
        name: "信息公示细节",
        component: () => import("../views/list/messageDetails.vue"),
      },
      {
        path: "/series",
        name: "产品系列",
        component: () => import("../views/list/series.vue"),
      },
      {
        path: "/testw",
        name: "测试",
        component: () => import("../views/list/testw.vue"),
      },
      {
        path: "/seriesdetail",
        name: "产品展示详情",
        component: () => import("../views/list/seriesdetail.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "后台登陆",
    component: () => import("../views/list/login.vue"),
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 };
  },
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
